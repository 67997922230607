
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Getter, State as StateClass } from 'vuex-class';
  import { State } from '@/models/State';
  import { Asset } from '@/models/assets/Asset';
  import { ManagerRole } from '@/models/manager/Manager';
  import { DataContainerStatus } from '@/models/Common';
  import { Trace } from '@/models/tracing/Trace';

  @Component({
    components: {
    },
  })
  export default class TracingTable extends Vue {
    options = {
      headings: {
        date: 'date',
        user: 'user',
        document: 'document',
        collection: 'collection',
        isEqual: 'changed',
      },
      filterable: ['date', 'user', 'document', 'collection', 'isEqual'],
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2 adds classes runtime without a space before
      columnsClasses: {
        date: 'table__col--date align-middle table__col--m ',
        user: 'table__col--user align-middle table__col--m ',
        document: 'table__col--document align-middle table__col--m ',
        collection: 'table__col--collection align-middle table__col--m ',
        isEqual: 'table__col--isEqual align-middle table__col--m ',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    };
    columns = ['date', 'user', 'document', 'collection', 'isEqual'];

    @StateClass tracing!: State['tracing'];

    /**
     * Returns whether we're loading data.
     */
    get loading(): boolean {
      return this.tracing.loading();
    }

    get tracingList(): Trace[] {
      return this.tracing.payload || [];
    }
  }
